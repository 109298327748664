<template>
  <div class=container>
    <table class=table width="709" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2"></font></div></td>
          </tr>
          <tr>
            <td><table width="1000" align="center" cellpadding="0" cellspacing="0">
              <tbody><tr>
                <td align="center" height="20" width="63"><a href="http://www.cs.columbia.edu/%7Ebosun">Bo Sun</a></td>
                <td align="center" height="20" width="125">Kalyan Sunkavalli</td>
                <td align="center" height="20" width="142">Ravi Ramamoorthi</td>
                <td align="center" height="20" width="123"><a href="http://www.cs.columbia.edu/%7Ebelhumeur">Peter Belhumeur</a></td>
                <td align="center" height="20" width="86"><a href="http://www.cs.columbia.edu/%7Enayar">Shree Nayar</a></td>
              </tr>
              <tr>
                <td colspan="5"><div align="center"><span class="style1">.</span><br>
                    <span class="style2">Columbia Univeristy</span></div></td>
              </tr>
            </tbody></table>            </td>
          </tr>
          <tr>
            <td ><img  align="center" src="https://cave.cs.columbia.edu/old/databases/tvbrdf/images/main.png" width="1000" height="200" border="1"></td>
          </tr>
          <tr>
            <td><table align="center" cellpadding="0" cellspacing="3">
              <tbody><tr>
                <td><div align="center"><router-link to="/repository/TVBRDF/about">About the Database</router-link></div></td>
              </tr>
              <tr>
                <td><div align="center"><router-link to="/repository/TVBRDF/measures">TVBRDF Measurement and Parameters</router-link></div></td>
              </tr>
              <tr>
                <td><div align="center"><router-link to="/repository/TVBRDF/documentation">Documentation</router-link></div></td>
              </tr>
              <tr>
                <td><div align="center"><router-link to="/repository/TVBRDF/download">Download the Database</router-link></div></td>
              </tr>
              <!-- <tr>
                <td><div align="center"><router-link to="http://www1.cs.columbia.edu/CAVE/projects/tvbrdf/tvbrdf.php">Project Page</router-link></div></td>
              </tr> -->
            </tbody></table>
            </td>
          </tr>
          <tr>
            <td><hr>              
  Contact:&nbsp;<a href="mailto:tvbrdf@cs.columbia.edu">tvbrdf@cs.columbia.edu</a><br>
  Last modified: 06/03/2007
          </td></tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>